@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css?family=Nanum+Gothic:400');
@import url('https://fonts.googleapis.com/css?family=Nanum+Myeongjo:400');
@import url('https://fonts.googleapis.com/css?family=Nanum+Pen+Script:400');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-barun-pen.css');
@import url('https://hangeul.pstatic.net/hangeul_static/css/nanum-square.css');

.custom_css {
    background-color: white !important;
    color: #6466e9 !important;
}

/*1. 나눔고딕*/
.nanumGothic {
    font-family: "Nanum Gothic";
}

/*2. 나눔명조*/
.nanumMyeongjo {
    font-family: "Nanum Myeongjo";
}

/*3. 나눔바른 펜*/
.nanumBarunpen {
    font-family: 'NanumBarunpen';
}

/*4. 나눔손글씨 펜*/
.nanumPen {
    font-family: "Nanum Pen Script";
}

/*5. 나눔스퀘어*/
.nanumSquare {
    font-family: 'NanumSquare';
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: rgb(99 102 241);

}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

label[for="file"] {
    color: green;
    min-width: 260px;
    max-width: 100%;
}

#fluid {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: -1;
}